import React, { Component } from 'react';
import uc from '../uc.svg';
import './About.css';

class About extends Component {
    render() {
        return (
            <div className="main-content">
                <div>
                    <img src={uc} style={{ height: 250 }} className="uc-img" alt="uc" />
                </div>
                <div>
                    <h2>نحن بصدد إنشاء هذه الصّفحة</h2>
                    <h2>ستكون جازهة قريبا إن شاء الله</h2>
                </div>
            </div>
        )
    }
}

export default About;