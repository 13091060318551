import { Route, Routes } from "react-router-dom";
import './App.css';
import Footer from "./Footer";
import Home from "./Pages/Home";
import Navbar from './Navbar';
import Ibadet from "./Pages/Ibadet";
import Library from "./Pages/Library";
import About from "./Pages/About";
import Contact from "./Pages/Contact";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/Ibadet' element={<Ibadet />} />
        <Route exact path='/library' element={<Library />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
