import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom";
import QABLogo from './QABLogo.svg';
import "./Navbar.css";

const Navbar = () => {
    return (<>
        <nav>
            <ul>
                <li>
                    <NavLink exact to="/contact" style={({ isActive }) => { return { fontWeight: isActive ? "bold" : "" }; }}>
                        تواصل معنا
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/about" style={({ isActive }) => { return { fontWeight: isActive ? "bold" : "" }; }}>
                        تعريف الجمعيّة
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/library" style={({ isActive }) => { return { fontWeight: isActive ? "bold" : "" }; }}>
                        مكتبة الجمعيّة
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/ibadet" style={({ isActive }) => { return { fontWeight: isActive ? "bold" : "" }; }}>
                        عبادات
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/" style={({ isActive }) => { return { fontWeight: isActive ? "bold" : "" }; }}>
                        الرّئيسيّة
                    </NavLink>

                </li>
            </ul>
            <a href="/" className="logo">
                <img src={QABLogo} style={{ height: 50 }} alt="Logo" />
            </a>
        </nav>
    </>
    )
}

export default Navbar;