import React, { Component } from 'react';
import './Home.css';

class Home extends Component {
    render() {
        return (
            <div>
                <div className="Home-content">
                    <h1>مرحبا بك</h1>
                    <h2>الموقع لا يزال طور الإنشاء</h2>
                    <h2>بإمكانك التّسجيل في الدّورة من هنا</h2>
                </div>
                <a href="https://portal.quranicbekalta.tn/session" className="btn">
                    سجّل من هنا
                </a>
            </div>
        )
    }
}

export default Home;
